import styled, { css } from 'styled-components'
import Image from 'next/image'
import { NextPage } from 'next'
import { Header } from '@root/modules/Header'
import { LoginCard } from '@root/modules/Auth'
import { GuestLayout } from '@root/layouts'

const StyledLoginPage = styled.div`
  height: 100vh;

  .moneylion-logo {
    position: fixed;
    top: 40px;
    left: 40px;
    z-index: 1;

    svg {
      height: 40px;
      width: 180px;
    }
  }

  .image-wrapper {
    width: 45.8%;
    position: relative;
    background: rgb(0, 0, 0) url(/assets/svgs/login.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .image-wrapper:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 20%,
      rgba(0, 0, 0, 0) 80%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  .login-page-image {
    object-fit: cover;
    height: 99vh;
    width: 100%;
  }

  .login-main-container {
    height: 100%;
    width: 54.2%;

    .login-card {
      max-width: 448px;
      flex: 1;
    }
  }

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
      display: block;

      .login-main-container {
        width: 100%;

        .login-card h1 {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 32px;
          text-align: center;
        }
        .create-account-label {
          text-align: center;
        }
      }
    }
  `}
`

const LoginPage: NextPage = () => {
  return (
    <GuestLayout title="Log In">
      <StyledLoginPage className="login-page flex jc-end">
        <Header className="hidden-desktop" />
        <div className="moneylion-logo">
          <Image
            src="/assets/svgs/ml-logo-white.svg"
            alt="MoneyLion Logo"
            width={180}
            height={40}
            priority
          />
        </div>
        <div className="image-wrapper hidden-mobile" />
        <div className="login-main-container flex jc-center ai-center pa-3">
          <LoginCard />
        </div>
      </StyledLoginPage>
    </GuestLayout>
  )
}

export default LoginPage
